import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserSecretDto } from '../dto/user-secret.dto';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { LoginDataMapper } from '../mappers/login-data.mapper';
import { PasswordChangeMapper } from '../mappers/password-change.mapper';
import { UserSecretDataMapper } from '../mappers/user-secret-data.mapper';
import { Login } from '../models/login';
import { PasswordChange } from '../models/password-change';
import { UserSecret } from '../models/user-secret';

import { AppUrlsConfig } from './app-urls-config';

const AUTH_PREFIX = 'Token';

/**
 * Stateless service for handling the authorization requests.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  public constructor(
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly loginDataMapper: LoginDataMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userSecretMapper: UserSecretDataMapper,
    private readonly passwordChangeMapper: PasswordChangeMapper,
  ) { }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<UserSecret> {
    return this.httpClient.post<UserSecretDto>(
      this.appUrlsConfig.auth.login,
      this.loginDataMapper.toDto(loginData),
    )
      .pipe(
        map(dto => this.userSecretMapper.fromDto(dto)),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.loginDataMapper,
        ),
      );
  }

  /** Logout current user. */
  public logout(): Observable<void> {
    return this.httpClient.post<void>(this.appUrlsConfig.auth.logout, {}).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Appends authorization header to a list of `headers`.
   * @param headers Headers list.
   * @param userSecret User secret.
   */
  public appendAuthorizationHeader(
    headers: HttpHeaders,
    userSecret: UserSecret,
  ): HttpHeaders {
    return headers.set('Authorization', `${AUTH_PREFIX} ${userSecret.token}`);
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: PasswordChange): Observable<void> {
    return this.httpClient.put<PasswordChange>(
      this.appUrlsConfig.auth.changePassword,
      this.passwordChangeMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.passwordChangeMapper,
        ),
      );
  }
}
