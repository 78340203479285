import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserAvatar } from '@hm/common/core/models/user';
import { assertNonNull } from '@hm/common/core/utils/assert-non-null';

type AvatarSize = 'small' | 'medium' | 'large';

/** Avatar component. */
@Component({
  selector: 'hmc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {

  /** Avatar source. */
  @Input()
  public user: UserAvatar | null = null;

  /**
   * Whether entity is system or not.
   * For example, sometimes we have some automatic rule syncs by the system without any user.
   */
  @Input()
  public isSystem = false;

  /** Avatar size. */
  @Input()
  public size: AvatarSize = 'medium';

  /** System avatar url. */
  private readonly systemAvatarUrl = 'favicon.ico';

  /** Obtain text for named avatar. */
  protected get namedAvatarText(): string | null {
    assertNonNull(this.user);
    if (this.user.name.trim() === '') {
      return null;
    }
    const [firstName, lastName] = this.user.name.split(' ');
    if (firstName == null || lastName == null) {
      return null;
    }
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  /** Obtain avatar url. */
  protected get avatarUrl(): string {
    if (this.user === null) {

      if (this.isSystem) {
        return this.systemAvatarUrl;
      }
      return '';
    }
    return this.user.avatarUri;
  }

  /** Whether user avatar should be displayed or not. */
  protected get shouldShowUserAvatar(): boolean {
    const hasUserAvatar = this.user !== null && this.user.avatarUri !== '';
    return this.isSystem || hasUserAvatar;
  }
}
