import { Pipe, PipeTransform } from '@angular/core';
import { AlertSortField } from '@hm/common/core/models/alert-sort-field';

const TO_ALERT_SORT_FIELD_MAP: Readonly<Record<AlertSortField, string>> = {
  [AlertSortField.CreatedDate]: 'Last Created',
  [AlertSortField.Priority]: 'Priority',
};

/** Alert sort field pipe. */
@Pipe({
  name: 'hmcAlertSortField',
})
export class AlertSortFieldPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(sortField: AlertSortField): string {
    return TO_ALERT_SORT_FIELD_MAP[sortField];
  }
}
