import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Destroyable } from '@hm/common/core/utils/destroyable';

/** Root component. */
@Destroyable()
@Component({
  selector: 'hmw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent { }
