import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { DialogsService } from '../core/services/dialogs.service';
import { NotificationService } from '../core/services/notification.service';

import { AvatarComponent } from './components/avatar/avatar.component';
import { FiltersDialogComponent } from './components/filters-dialog/filters-dialog.component';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { LabelComponent } from './components/label/label.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PasswordFieldControlComponent } from './components/password-field-control/password-field-control.component';
import { SecondarySelectComponent } from './components/secondary-select/secondary-select.component';
import { TableComponent } from './components/table/table.component';
import { TypedMatCellDirective } from './components/typed-mat-cell.directive';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { HasUserRoleDirective } from './directives/has-user-role.directive';
import { LoadingDirective } from './directives/loading.directive';
import { SkeletonDirective } from './directives/skeleton.directive';
import { MaterialModule } from './material.module';
import { AlertSortFieldPipe } from './pipes/alert-sort-field.pipe';
import { AlertTypePipe } from './pipes/alert-type.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const PRIVATE_DECLARATIONS: Type<unknown>[] = [ValidationMessageComponent];

const EXPORTED_DECLARATIONS: Type<unknown>[] = [

  // Components
  FormControlValidationMessageComponent,
  LabelComponent,
  PasswordFieldControlComponent,
  FiltersDialogComponent,
  SecondarySelectComponent,
  TableComponent,
  AvatarComponent,
  LoadingComponent,

  // Directives
  LoadingDirective,
  SkeletonDirective,
  HasUserRoleDirective,
  TypedMatCellDirective,

  // Pipes
  TruncatePipe,
  AlertTypePipe,
  AlertSortFieldPipe,
];

const EXPORTED_MODULES: Type<unknown>[] = [
  MaterialModule,
  InfiniteScrollModule,
];

/** Common module. Contains entities shared across applications. */
@NgModule({
  declarations: [...PRIVATE_DECLARATIONS, ...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...EXPORTED_MODULES,
  ],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
  providers: [DatePipe, DialogsService, NotificationService],
})
export class CommonSharedModule {}
