<label class="label">
  <div class="label__wrapper">
    <div
      class="label__heading"
      *ngIf="labelText !== null"
      [class.visually-hidden]="visuallyHidden"
    >
      <span class="label__content">
        {{ labelText }}
      </span>
      <ng-content select="hmc-icon-tooltip"></ng-content>
    </div>
    <div class="label__input">
      <div class="label__input-prefix">
        <ng-content select="prefix"></ng-content>
      </div>
      <ng-content></ng-content>
      <div class="label__input-suffix">
        <ng-content select="suffix"></ng-content>
      </div>
    </div>
  </div>
  <hmc-validation-message class="error" [errors]="errors$ | async">
  </hmc-validation-message>
</label>
