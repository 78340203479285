import { Injectable } from '@angular/core';
import { AppConfigService } from '@hm/common/core/services/app-config.service';
import { environment } from 'projects/web/src/environments/environment';

/** App-specific implementation of app config. */
@Injectable()
export class WebAppConfigService extends AppConfigService {
  /** @inheritdoc */
  public readonly apiUrl: string = environment.apiUrl;

  /** @inheritdoc */
  public readonly version: string = environment.version;

  /** @inheritdoc */
  public readonly officeUrl: string = environment.officeUrl;
}
