<hmc-label class="no-spacing">
  <div class="select">
    <span *ngIf="label" class="select__label">{{ label }}:</span>
    <mat-select
      #selected
      *ngIf="control$ | async as control"
      disableOptionCentering="true"
      class="select__content secondary-select"
      panelClass="secondary-select-panel"
      [class.is-open]="isOpened$ | async"
      [class.without-arrow]="withoutMatArrow"
      [placeholder]="placeholder"
      [formControl]="control"
      (openedChange)="toggleSelectOpened($event)"
      [compareWith]="(comparator$ | async) ?? selected.compareWith"
    >
      <mat-select-trigger *ngIf="triggerContent">
        <ng-container
          *ngTemplateOutlet="
            triggerContent;
            context: getOptionContent(control.value)
          "
        ></ng-container>
      </mat-select-trigger>
      <div class="secondary-select__options-wrapper">
        <mat-option *ngFor="let item of items" [value]="item">
          <ng-container *ngIf="!itemContent">{{ item }}</ng-container>
          <ng-container
            *ngTemplateOutlet="itemContent; context: getOptionContent(item)"
          ></ng-container>
        </mat-option>
      </div>
    </mat-select>
  </div>
</hmc-label>
