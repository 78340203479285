export namespace DateUtils {

  /**
   * Whether date is valid or not.
   * @param date Date.
   */
  export function isValidDate(date: Date): boolean {
    return (
      Object.prototype.toString.call(date) === '[object Date]' &&
      !Number.isNaN(date.getTime())
    );
  }
}
