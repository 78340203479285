import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_STRING_LENGTH = 70;
const COMPLETE_WORD_SYMBOL = ' ';
const DEFAULT_ELLIPSIS_SYMBOL = '';

/** Truncate long string pipe. */
@Pipe({ name: 'hmcTruncate' })
export class TruncatePipe implements PipeTransform {

  /**
   * Transform a long string to a short by truncate this one.
   * @param value Unformatted long string.
   * @param limit Limit string length.
   * @param ellipsis Ellipsis symbol.
   * @param completeWords Should complete words.
   */
  public transform(
    value: string,
    limit = DEFAULT_STRING_LENGTH,
    ellipsis = DEFAULT_ELLIPSIS_SYMBOL,
    completeWords = true,
  ): string {
    const startPosition = 0;
    const _limit = completeWords ? value.substring(startPosition, startPosition + limit).lastIndexOf(COMPLETE_WORD_SYMBOL) : limit;
    return value.length > limit ? value.substring(startPosition, _limit) + ellipsis : value;
  }
}
