<h2 class="heading">Filter</h2>
<ng-content></ng-content>
<div class="controls">
  <button
    class="controls__reset button"
    type="button"
    (click)="onResetAllButtonClick()"
  >
    Reset All
  </button>
  <div class="controls__actions">
    <button
      class="controls__cancel-button"
      type="button"
      (click)="onCancelButtonClick()"
    >
      Cancel
    </button>
    <button class="primary" type="button" (click)="onApplyButtonClick()">
      Apply
    </button>
  </div>
</div>
