import { buildRoutePaths } from './build-route-paths';

export const ALERT_ID_PARAM_NAME = 'alertId';

const baseRoutePaths = buildRoutePaths({
  root: { path: '' },
} as const);

const authRoutePaths = buildRoutePaths({
  auth: {
    path: 'auth',
    children: {
      login: { path: 'login' },
    },
  },
} as const);

const portalRoutePaths = buildRoutePaths({
  home: {
    path: 'home',
    children: {
      alerts: {
        path: 'alerts',
        children: {
          details: { path: `:${ALERT_ID_PARAM_NAME}` },
        },
      },
      actionItems: { path: 'action-items' },
      settings: { path: 'settings' },
      users: { path: 'users' },
      userEdit: { path: 'user-edit' },
      userDetails: { path: 'user-details' },
      dashboard: { path: 'dashboard' },
      ruleDetails: { path: 'rule-details' },
      ruleEdit: { path: 'rule-edit' },
      rules: { path: 'rules' },
    },
  },
} as const);

/** Route paths can be used throughout the project. */
export const routePaths = {
  ...baseRoutePaths,
  ...authRoutePaths,
  ...portalRoutePaths,
};
