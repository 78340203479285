import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init, BrowserTracing, routingInstrumentation } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

init({
  dsn: environment.sentryDsn,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [environment.apiUrl],
      routingInstrumentation,
    }),
  ],
  environment: environment.environment,
  enabled: environment.environment === 'development',
  release: environment.version,
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
