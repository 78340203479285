import { Injectable } from '@angular/core';

import { ProfileImageChangeDto } from '../dto/profile-image-change.dto';
import { ProfileImageChange } from '../models/profile-image-change';

import { IMapperToDto } from './mappers';

/**
 * Mapper for change profile image process.
 */
@Injectable({ providedIn: 'root' })
export class ProfileImageChangeMapper implements IMapperToDto<ProfileImageChangeDto, ProfileImageChange> {

  /** @inheritdoc */
  public toDto(data: ProfileImageChange): ProfileImageChangeDto {
    return {
      avatar: data.avatar,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: ProfileImageChangeDto): ProfileImageChange {
    return {
      avatar: dto.avatar,
    };
  }
}
