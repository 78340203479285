import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from '@hm/common/core/models/alert-type';

const TO_READABLE_ALERT_TYPE_MAP: Readonly<Record<AlertType, string>> = {
  [AlertType.WithActionItem]: 'Alert with Action Item',
  [AlertType.WithoutActionItem]: 'Alert without Action Item',
};

/** Alert type pipe. */
@Pipe({ name: 'hmcAlertType' })
export class AlertTypePipe implements PipeTransform {

  /** @inheritdoc */
  public transform(alertType: AlertType): string {
    return TO_READABLE_ALERT_TYPE_MAP[alertType];
  }
}
