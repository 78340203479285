import { inject, Injectable } from '@angular/core';

import { UserProfileDto } from '../dto/user.dto';
import { UserProfile } from '../models/user';

import { DepartmentMapper } from './department.mapper';
import { IMapperFromDto } from './mappers';
import { UserRoleMapper } from './user-role.mapper';
import { UserShortMapper } from './user-short.mapper';

/** User base mapper. */
@Injectable({ providedIn: 'root' })
export class UserProfileMapper implements IMapperFromDto<UserProfileDto, UserProfile> {

  private readonly departmentMapper = inject(DepartmentMapper);

  private readonly userRoleMapper = inject(UserRoleMapper);

  private readonly userShortMapper = inject(UserShortMapper);

  /** @inheritdoc */
  public fromDto(data: UserProfileDto): UserProfile {
    return {
      id: data.id,
      name: data.full_name ?? '',
      email: data.email,
      avatarUri: data.avatar ?? '',
      avatarThumbnailUri: data.avatar_thumbnail ?? '',
      role: this.userRoleMapper.fromDto(data.role),
      departmentId: data.department ?? null,
      department: data.department_data ? this.departmentMapper.fromDto(data.department_data) : null,
      subordinatesIds: data.subordinates ?? [],
      subordinates: data.subordinates_data.map(subordinate => this.userShortMapper.fromDto(subordinate)),
      alertsCount: data.alerts_count,
    };
  }
}
