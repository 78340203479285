import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserProfileDto } from '../dto/user.dto';
import { UserProfileMapper } from '../mappers/user-profile.mapper';
import { UserProfile } from '../models/user';

import { AppUrlsConfig } from './app-urls-config';

/** Performs CRUD operations for users. */
@Injectable({
  providedIn: 'root',
})
export class UserApiService {

  private readonly appUrlsConfig = inject(AppUrlsConfig);

  private readonly httpClient = inject(HttpClient);

  private readonly userProfileMapper = inject(UserProfileMapper);

  /** Returns current user info.*/
  public getCurrentUser(): Observable<UserProfile> {
    return this.httpClient
      .get<UserProfileDto>(this.appUrlsConfig.currentUser.profile)
      .pipe(
        map(user => this.userProfileMapper.fromDto(user)),
      );
  }
}
