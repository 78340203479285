import { Injectable } from '@angular/core';

import { DepartmentDto } from '../dto/department.dto';
import { Department } from '../models/department';

import { IMapper } from './mappers';

/** Department mapper. */
@Injectable({ providedIn: 'root' })
export class DepartmentMapper implements IMapper<DepartmentDto, Department> {

  /** @inheritdoc */
  public fromDto(data: DepartmentDto): Department {
    return {
      id: data.id,
      name: data.name,
    };
  }

  /** @inheritdoc */
  public toDto(data: Department): DepartmentDto {
    return {
      id: data.id,
      name: data.name,
    };
  }
}
