import { ChangeDetectionStrategy, Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { QueryParamsService } from '@hm/common/core/services/query-params.service';

/** Filters dialog component. */
@Component({
  selector: 'hmc-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersDialogComponent {

  /** Apply event emitter. */
  @Output()
  public readonly apply = new EventEmitter<void>();

  public constructor(
    private readonly dialogRef: MatDialogRef<FiltersDialogComponent>,
    private readonly queryParamsService: QueryParamsService,
  ) {}

  /** Handles click on reset all button. */
  protected onResetAllButtonClick(): void {
    this.queryParamsService.reset();
    this.dialogRef.close();
  }

  /** Handles click on cancel button. */
  protected onCancelButtonClick(): void {
    this.dialogRef.close();
  }

  /** Handles click on apply button. */
  protected onApplyButtonClick(): void {
    this.apply.emit();
    this.dialogRef.close();
  }
}
