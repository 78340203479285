import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@hm/common/core/interceptors/auth-interceptor';
import { ValidationTokenInterceptor } from '@hm/common/core/interceptors/validation-token-interceptor';
import { AppConfigService } from '@hm/common/core/services/app-config.service';
import { CommonSharedModule } from '@hm/common/shared/common-shared.module';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebAppConfigService } from './features/shared/web-app-config.service';

const httpInterceptorProviders = [
  // The validation interceptor should be before the auth interceptor, otherwise token won't be validated.
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ValidationTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

const sentryProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line no-empty-function
    useFactory: () => () => {},
    deps: [TraceService],
    multi: true,
  },
];

/** Root module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonSharedModule,
  ],
  providers: [
    ...httpInterceptorProviders,
    ...sentryProviders,
    { provide: AppConfigService, useClass: WebAppConfigService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
