import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { CloseScrollStrategy, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/**
 * Scroll factory for autocomplete.
 * Change autocomplete scroll strategy, because autocomplete update position doesn't fit
 * when we have scrollable modal because autocomplete panel has high z-index value and is out of document flow.
 * @param overlay Overlay.
 */
export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

const EXPORTED_MODULES: Type<unknown>[] = [
  MatIconModule,
  MatButtonModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  OverlayModule,
  MatAutocompleteModule,
  MatChipsModule,
  ScrollingModule,
  MatDatepickerModule,
  MatSlideToggleModule,
];

/** Material module. Contains only material entities shared across applications. */
@NgModule({
  imports: [
    CommonModule,
    ...EXPORTED_MODULES,
  ],
  exports: [...EXPORTED_MODULES],
  providers: [{ provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }],
})
export class MaterialModule {}
