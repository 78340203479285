import { BaseFilterParams } from '@hm/common/core/models/base-filter-params';
import { BehaviorSubject, filter, map, merge, Observable, tap } from 'rxjs';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatHeaderRowDef, MatNoDataRow, MatRowDef, MatTable } from '@angular/material/table';
import { Destroyable, takeUntilDestroy } from '@hm/common/core/utils/destroyable';
import { Pagination } from '@hm/common/core/models/pagination';
import { assertNonNull } from '@hm/common/core/utils/assert-non-null';

const DEFAULT_PAGINATION_FILTERS: BaseFilterParams.Pagination = {
  pageNumber: 0,
  pageSize: 10,
};

/** Table component. */
@Destroyable()
@Component({
  selector: 'hmc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements AfterViewInit, AfterContentInit {

  /** Items that will be displayed on the table. */
  @Input()
  public set page(p: Pagination<T> | null) {
    if (p?.hasItems) {
      this.tableData$.next(p.items);
      this.itemsCount$.next(p.totalCount);
    } else {
      this.tableData$.next([]);
    }
  }

  /** Displayed columns. */
  @Input()
  public displayedColumns: readonly string[] = [];

  /** Pagination filters. */
  @Input()
  public paginationFilters: BaseFilterParams.Pagination = DEFAULT_PAGINATION_FILTERS;

  /** Pagination changes event. */
  @Output()
  public readonly pageChange = new EventEmitter<BaseFilterParams.Pagination>();

  /** Paginator component. */
  @ViewChild('paginator')
  public paginator?: MatPaginator;

  /** Table element. */
  @ViewChild(MatTable, { static: true })
  public table?: MatTable<T>;

  /** Header rows. */
  @ContentChildren(MatHeaderRowDef)
  public headerRows?: QueryList<MatHeaderRowDef>;

  /** Data row definitions list. */
  @ContentChildren(MatRowDef)
  public rowDefs?: QueryList<MatRowDef<T>>;

  /** Data column definitions. */
  @ContentChildren(MatColumnDef)
  public columnDefs?: QueryList<MatColumnDef>;

  /** No data row. */
  @ContentChild(MatNoDataRow)
  public noDataRow?: MatNoDataRow;

  /** Data for the table. */
  protected readonly tableData$ = new BehaviorSubject<readonly T[]>([]);

  /** Total items count. */
  protected readonly itemsCount$ = new BehaviorSubject<number>(0);

  /** Current page number. */
  private pageNumber = this.paginationFilters.pageNumber;

  /** Current page size. */
  private pageSize = this.paginationFilters.pageSize;

  /*
   * Whether pagination is first loaded or not.
   * We need to add this variable to make sure when page size of pagination differ from the default,
   * the first load will not make `isPaginationChanged` false.
   * The reason is `pageSize` variable is equal to default pagination size in first load.
   * When page size of pagination differ from the default, it will make `isPaginationChanged`
   * false and not trigger the change event.
   */
  private isFirstLoad = true;

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    merge(
      this.pageChangeSideEffect(),
    ).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    this.columnDefs?.forEach(columnDef => this.table?.addColumnDef(columnDef));
    this.rowDefs?.forEach(rowDef => this.table?.addRowDef(rowDef));
    this.headerRows?.forEach(headerRowDef => this.table?.addHeaderRowDef(headerRowDef));
    this.table?.setNoDataRow(this.noDataRow ?? null);
  }

  private pageChangeSideEffect(): Observable<void> {
    assertNonNull(this.paginator);
    return this.paginator.page.pipe(
      filter(page => this.isPaginationChanged(page)),
      tap(page => this.triggerPageChange(page)),
      map(() => undefined),
    );
  }

  private triggerPageChange(page: PageEvent): void {
    const shouldResetPageNumber = this.pageSize !== page.pageSize;
    const newPageNumber = shouldResetPageNumber ? 0 : page.pageIndex;
    this.pageNumber = newPageNumber;
    this.pageSize = page.pageSize;
    this.isFirstLoad = false;
    this.pageChange.emit({ pageNumber: newPageNumber, pageSize: page.pageSize });
  }

  private isPaginationChanged(page: PageEvent): boolean {
    return this.isFirstLoad ? true : this.pageNumber !== page.pageIndex || this.pageSize !== page.pageSize;
  }
}
