/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/** Query params service. */
@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {

  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Obtain value from provided key.
   * @param key Key of the query param.
   */
  public obtain(key: string): string | null {
    const value = this.activatedRoute.snapshot.queryParamMap.get(key);
    if (value == null) {
      return null;
    }
    return value;
  }

  /**
   * Append provide query params to the URL.
   * @param params Params to append.
   */
  public append(params: Record<string, any>): void {
    this.router.navigate([], {
      queryParams: {
        ...params,
      },
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Remove specific param from the query.
   * @param param Param to remove.
   */
  public remove(param: string): void {
    const hasParam = this.activatedRoute
      .snapshot
      .queryParamMap
      .get(param) != null;

    if (hasParam) {
      this.router.navigate([], {
        queryParams: {
          [param]: null,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'merge',
      });
    }
  }

  /** Reset all query params. */
  public reset(): void {
    this.router.navigate([], {
      queryParams: null,
      relativeTo: this.activatedRoute,
    });
  }
}
