import { inject, Injectable } from '@angular/core';

import { ActionItem } from '../models/action-item';
import { Alert } from '../models/alert';
import { Campaign } from '../models/campaign';
import { Department } from '../models/department';
import { Rule } from '../models/rule';
import { State } from '../models/state';
import { User } from '../models/user';

import { AppConfigService } from './app-config.service';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
  private readonly appConfigService = inject(AppConfigService);

  /** Auth-related routes. */
  public readonly auth = {
    login: this.toApi('auth/login/'),
    changePassword: this.toApi('auth/change-password/'),
    logout: this.toApi('auth/logout/'),
  } as const;

  /** Current user related routes. */
  public readonly currentUser = {
    profile: this.toApi('users/profile/'),
  } as const;

  /** User-related routes. */
  public readonly user = {
    list: this.toApi('users/'),
    details: (id: User['id']) => this.toApi(`users/${id}/`),
    departments: this.toApi('users/departments/'),
    department: (id: Department['id']) => this.toApi(`users/departments/${id}/`),
    subordinates: this.toApi('users/available-subordinates/'),
  } as const;

  /** Action item related routes. */
  public readonly actionItem = {
    list: this.toApi('action-items/'),
    details: (id: ActionItem['id']) => this.toApi(`action-items/${id}/`),
  } as const;

  /** Alerts-related routes. */
  public readonly alert = {
    list: this.toApi('alerts/'),
    details: (id: Alert['id']) => this.toApi(`alerts/${id}/`),
    updateStatus: this.toApi('alerts/update-status/'),
  } as const;

  /** Campaign-related routes. */
  public readonly campaign = {
    list: this.toApi('campaigns/'),
    parameters: this.toApi('campaigns/parameters/'),
    parameter: (id: Campaign['id']) => this.toApi(`campaigns/parameters/${id}/`),
    states: this.toApi('campaigns/states/'),
    state: (id: State['id']) => this.toApi(`campaigns/states/${id}/`),
  } as const;

  /** Dashboard-related routes. */
  public readonly dashboard = {
    actionItemStats: this.toApi('dashboard/action-item-stats/'),
    activities: this.toApi('dashboard/activities/'),
  } as const;

  /** Rules-related routes. */
  public readonly rule = {
    list: this.toApi('rules/'),
    details: (id: Rule['id']) => this.toApi(`rules/${id}/`),
    sync: (id: Rule['id']) => this.toApi(`rules/${id}/sync-rule/`),
  } as const;

  /** URLs of external storages. */
  public readonly storage = {
    getParams: this.toApi('s3/get_params/'),
  } as const;

  /**
   * Checks whether the url is application-scoped.
   * @param url Url to check.
   */
  public isApplicationUrl(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl);
  }

  /**
   * Checks whether the specified url is calling an auth-related endpoint.
   * @param url Url to check.
   */
  public isAuthUrl(url: string): boolean {
    return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
  }

  private toApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfigService.apiUrl).toString();
  }
}
