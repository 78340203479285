import { Injectable } from '@angular/core';

import { DateUtils } from '../utils/date-utils';

import { IMapper } from './mappers';

/** Date mapper. */
@Injectable({
  providedIn: 'root',
})
export class DateMapper implements IMapper<string, Date> {

  /** @inheritdoc */
  public fromDto(data: string): Date {
    const dateCandidate = new Date(data);
    if (DateUtils.isValidDate(dateCandidate)) {
      return dateCandidate;
    }
    return new Date();
  }

  /** @inheritdoc */
  public toDto(data: Date): string {
    return data.toISOString();
  }
}
