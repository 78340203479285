import { inject, Injectable } from '@angular/core';

import { UserShortDto } from '../dto/user.dto';
import { UserShort } from '../models/user';

import { DateMapper } from './date.mapper';
import { DepartmentMapper } from './department.mapper';
import { IMapperFromDto } from './mappers';
import { UserRoleMapper } from './user-role.mapper';

/** User short mapper. */
@Injectable({ providedIn: 'root' })
export class UserShortMapper implements IMapperFromDto<UserShortDto, UserShort> {

  private readonly departmentMapper = inject(DepartmentMapper);

  private readonly userRoleMapper = inject(UserRoleMapper);

  private readonly dateMapper = inject(DateMapper);

  /** @inheritdoc */
  public fromDto(data: UserShortDto): UserShort {
    return {
      id: data.id,
      name: data.full_name ?? '',
      avatarUri: data.avatar ?? '',
      avatarThumbnailUri: data.avatar_thumbnail ?? '',
      role: this.userRoleMapper.fromDto(data.role),
      departmentId: data.department ?? null,
      department: data.department_data ? this.departmentMapper.fromDto(data.department_data) : null,
      lastLoginDate: data.last_login ? this.dateMapper.fromDto(data.last_login) : null,
    };
  }
}
