import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filterNull } from '@hm/common/core/utils/rxjs/filter-null';
import { ViewContext } from '@hm/common/core/utils/types/view-context';
import { BehaviorSubject, map, Observable, ReplaySubject, shareReplay } from 'rxjs';

type ComparatorFunction<T> = (object1: T, object2: T) => boolean;

/** Select configuration. */
export interface SelectConfiguration<T> {

  /** Comparator function. */
  readonly comparator: ComparatorFunction<T> | undefined;
}

/** Secondary select. */
@Component({
  selector: 'hmc-secondary-select',
  templateUrl: './secondary-select.component.html',
  styleUrls: ['./secondary-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondarySelectComponent<T> {

  /** Items. */
  @Input()
  public items: readonly T[] | null = [];

  /** Item content template. */
  @Input()
  public itemContent: TemplateRef<unknown> | null = null;

  /** Trigger content template. */
  @Input()
  public triggerContent: TemplateRef<unknown> | null = null;

  /** Placeholder. */
  @Input()
  public placeholder = 'Select';

  /** Label. */
  @Input()
  public label = '';

  /** Select without material arrow. */
  @Input()
  public withoutMatArrow = false;

  /** Select configuration. */
  @Input()
  public set configuration(config: SelectConfiguration<T> | null) {
    if (config !== null) {
      this.configuration$.next(config);
    }
  }

  /** Form control setter. */
  @Input()
  public set control(value: FormControl<T> | null) {
    if (value != null) {
      this.control$.next(value);
    }
  }

  /** Password field control. */
  protected readonly control$ = new ReplaySubject<FormControl<T>>(1);

  /** Is select opened state. */
  protected readonly isOpened$ = new BehaviorSubject(false);

  /** Function, obtained from configuration, compare entities from the list. */
  protected readonly comparator$: Observable<ComparatorFunction<T>>;

  private readonly configuration$ = new ReplaySubject<SelectConfiguration<T>>(1);

  public constructor() {
    this.comparator$ = this.initializeComparator();
  }

  /**
   * Get option content.
   * @param item Select option.
   */
  protected getOptionContent(item: T): ViewContext<T> {
    return {
      $implicit: item,
    };
  }

  /**
   * Toggle select opened state.
   * @param isOpen Is select opened.
   */
  protected toggleSelectOpened(isOpen: boolean): void {
    this.isOpened$.next(isOpen);
  }

  /** Initialize selected value. */
  protected initializeSelectedValue(): Observable<T> {
    return this.control$.pipe(
      map(control => control.value),
    );
  }

  private initializeComparator(): Observable<ComparatorFunction<T>> {
    return this.configuration$.pipe(
      map(configuration => configuration.comparator),
      filterNull(),
      map(comparator => (object1: T, object2: T) => comparator(object1, object2)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
