import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '@hm/common/core/models/user-role';
import { UserService } from '@hm/common/core/services/user.service';
import { Destroyable, takeUntilDestroy } from '@hm/common/core/utils/destroyable';
import { filterNull } from '@hm/common/core/utils/rxjs/filter-null';
import { map } from 'rxjs';

import { ConditionalRenderingDirective } from './conditional-rendering.directive';

/**
 * Directive that render content only if
 * user's current role is the same as the one passed.
 */
@Destroyable()
@Directive({
  selector: '[hmcUserHasRole]',
})
export class HasUserRoleDirective extends ConditionalRenderingDirective implements OnInit {

  /** The role that the current user must have. */
  @Input()
  public hmcUserHasRole: UserRole | null = null;

  public constructor(
    vcr: ViewContainerRef,
    cdr: ChangeDetectorRef,
    templateRef: TemplateRef<unknown>,
    private readonly userService: UserService,
  ) {
    super(vcr, cdr, templateRef);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.userService.currentUser$.pipe(
      filterNull(),
      map(user => user.role === this.hmcUserHasRole),
      takeUntilDestroy(this),
    )
      .subscribe(hasUserPermission => {
        if (hasUserPermission) {
          this.renderContent();
        } else {
          this.clearContent();
        }
      });
  }
}
