import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, switchMap, throwError } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls-config';
import { UserService } from '../services/user.service';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

/** Interceptor handles requests with outdated tokens. */
@Injectable()
export class ValidationTokenInterceptor implements HttpInterceptor {

  public constructor(
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly userService: UserService,
  ) { }

  /**
   * Refreshes a token.
   * @inheritdoc
   */
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchHttpErrorResponse(error => {
          if (error.status !== 401 || !this.shouldValidateTokenForUrl(req.url)) {
            return throwError(() => error);
          }
          return this.userService.forcedLogout().pipe(
            switchMap(() => EMPTY),
          );
        }),
    );
  }

  /**
   * Checks if a request is for authorization or refresh token.
   * @param url Request url.
   */
  private shouldValidateTokenForUrl(url: string): boolean {
    return this.appUrlsConfig.isApplicationUrl(url) && !this.appUrlsConfig.isAuthUrl(url);
  }
}
