import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { filterNull } from '../utils/rxjs/filter-null';

/** Dialogs service. */
@Injectable({
  providedIn: 'root',
})
export class DialogsService {

  public constructor(
    private readonly dialog: MatDialog,
  ) { }

  /**
   * Opens dialog.
   * @param component Component for which dialog should be opened.
   * @param config Dialog config.
   * @param dialogData Dialog data.
   */
  public openDialogWith<TComponent, TData, TResult>(
    component: ComponentType<TComponent>,
    dialogData?: TData,
    config?: MatDialogConfig<TData>,
  ): Observable<TResult> {
    return this.dialog.open<TComponent, TData, TResult>(component, {
      width: '480px',
      data: dialogData,
      ...config,
    }).afterClosed()
      .pipe(filterNull());
  }
}
