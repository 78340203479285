<hmc-label
  *ngIf="control$ | async as control"
  class="password"
  [labelText]="labelText"
>
  <input
    class="has-suffix"
    [formControl]="control"
    autocomplete="current-password"
    [type]="shouldShowPassword ? 'text' : 'password'"
    [placeholder]="placeholder"
  />
  <button
    class="password__toggle-button"
    ngProjectAs="suffix"
    mat-icon-button
    type="button"
    (click)="toggleShowPassword()"
  >
    <mat-icon *ngIf="shouldShowPassword" title="Hide password">
      visibility_off
    </mat-icon>
    <mat-icon *ngIf="!shouldShowPassword" title="Show password">
      visibility
    </mat-icon>
  </button>
</hmc-label>
