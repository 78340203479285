import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const DEFAULT_SPINNER_SIZE = 50;

type LoadingMode = 'bar' | 'spinner';

/** Loading component. */
@Component({
  selector: 'hmc-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {

  /** Spinner diameter size. */
  @Input()
  public size = DEFAULT_SPINNER_SIZE;

  /** Loading for skeleton. */
  @Input()
  public forSkeleton = false;

  /** Loading mode. */
  @Input()
  public mode: LoadingMode = 'spinner';
}
