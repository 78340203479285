import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  finalize, map, Observable, of,
  shareReplay, switchMap, Subject,
  tap,
  repeat,
  first,
  pipe,
  merge,
  OperatorFunction,
  ignoreElements,
} from 'rxjs';

import { Login } from '../models/login';
import { PasswordChange } from '../models/password-change';
import { User, UserProfile } from '../models/user';
import { ProfileImageChange } from '../models/profile-image-change';
import { ProfileImageChangeMapper } from '../mappers/profile-image-change.mapper';
import { filterNull } from '../utils/rxjs/filter-null';
import { routePaths } from '../utils/route-paths/route-paths';
import { UserSecret } from '../models/user-secret';

import { AuthService } from './auth.service';
import { UserSecretStorageService } from './user-secret-storage.service';
import { AppUrlsConfig } from './app-urls-config';
import { UserApiService } from './user-api.service';

/**
 * Stateful service for storing/managing information about the current user.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Current user. Null when user is not logged in. */
  public readonly currentUser$: Observable<UserProfile | null>;

  /** Whether the user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  private readonly routePaths = routePaths;

  private readonly userProfileUpdated$ = new Subject<void>();

  public constructor(
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly userSecretStorage: UserSecretStorageService,
    private readonly profileImageChangeMapper: ProfileImageChangeMapper,
    private readonly userApiService: UserApiService,
  ) {
    this.currentUser$ = this.initCurrentUserStream();
    this.isAuthorized$ = this.currentUser$.pipe(map(user => user != null));
  }

  /**
   * Checks if the passed user is the current user .
   * @param id User ID.
   */
  public isCurrentUser(id: User['id']): Observable<boolean> {
    return this.currentUser$.pipe(
      filterNull(),
      map(currentUser => currentUser.id === id),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<void> {
    return this.authService.login(loginData).pipe(
      this.saveSecretAndWaitForAuthorized(),
      finalize(() => this.redirectAfterAuthorization()),
    );
  }

  /**
   * Logout current user.
   */
  public logout(): Observable<void> {
    return this.authService.logout().pipe(
      switchMap(() => this.forcedLogout()),
    );
  }

  /** Forced user logout. */
  public forcedLogout(): Observable<void> {
    return this.userSecretStorage.removeSecret().pipe(
      finalize(() => this.navigateToAuthPage()),
    );
  }

  /**
   * Change Password.
   * @param data Password change data.
   */
  public changePassword(data: PasswordChange): Observable<void> {
    return this.authService.changePassword(data).pipe(
      switchMap(() => this.forcedLogout()),
    );
  }

  /**
   * Changes profile image of current user.
   * @param imageUrl New image url.
   */
  public changeProfileImage(imageUrl: string): Observable<void> {
    return this.httpClient.put<ProfileImageChange>(
      this.appUrlsConfig.currentUser.profile,
      this.profileImageChangeMapper.toDto({ avatar: imageUrl }),
    )
      .pipe(
        tap(() => this.markUserProfileUpdated()),
        map(() => undefined),
      );
  }

  /** Marks user profile as updated. */
  public markUserProfileUpdated(): void {
    this.userProfileUpdated$.next();
  }

  private initCurrentUserStream(): Observable<UserProfile | null> {
    return this.userSecretStorage.currentSecret$.pipe(
      switchMap(secret => (secret ? this.getCurrentUser() : of(null))),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private async redirectAfterAuthorization(): Promise<void> {
    const DEFAULT_REDIRECT_URL = this.routePaths.root;
    const route = this.router.createUrlTree([DEFAULT_REDIRECT_URL]);
    await this.router.navigateByUrl(route);
  }

  private getCurrentUser(): Observable<UserProfile> {
    return this.userApiService.getCurrentUser().pipe(
      repeat({ delay: () => this.userProfileUpdated$ }),
    );
  }

  private saveSecretAndWaitForAuthorized(): OperatorFunction<UserSecret, void> {
    return pipe(
      switchMap(secret => {
        const saveUserSecretSideEffect$ = this.userSecretStorage
          .saveSecret(secret)
          .pipe(ignoreElements());

        return merge(
          this.isAuthorized$,
          saveUserSecretSideEffect$,
        );
      }),
      first(Boolean),
      map(() => undefined),
    );
  }

  private async navigateToAuthPage(): Promise<void> {
    await this.router.navigateByUrl(this.routePaths.auth.url);
  }
}
