import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '@hm/common/core/guards/authorized.guard';
import { UnauthorizedGuard } from '@hm/common/core/guards/unauthorized.guard';
import { routePaths } from '@hm/common/core/utils/route-paths/route-paths';

const routes: Routes = [
  {
    path: '',
    redirectTo: routePaths.home.path,
    pathMatch: 'full',
  },
  {
    path: routePaths.auth.path,
    canMatch: [AuthorizedGuard],
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: routePaths.home.path,
    canMatch: [UnauthorizedGuard],
    loadChildren: () => import('./features/portal/portal.module')
      .then(m => m.PortalModule),
  },
  {
    path: '**',
    redirectTo: routePaths.home.path,
  },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
