import { Injectable } from '@angular/core';

import { UserRoleDto } from '../dto/user-role.dto';
import { UserRole } from '../models/user-role';
import { reverseRecord } from '../utils/reverse-record';

import { IMapper } from './mappers';

const TO_USER_ROLE_DTO: Readonly<Record<UserRole, UserRoleDto>> = {
  [UserRole.Admin]: UserRoleDto.Admin,
  [UserRole.User]: UserRoleDto.User,
};

const FROM_USER_ROLE_DTO = reverseRecord(TO_USER_ROLE_DTO);

/** User role mapper. */
@Injectable({ providedIn: 'root' })
export class UserRoleMapper implements IMapper<UserRoleDto, UserRole> {

  /** @inheritdoc */
  public fromDto(data: UserRoleDto): UserRole {
    return FROM_USER_ROLE_DTO[data];
  }

  /** @inheritdoc */
  public toDto(data: UserRole): UserRoleDto {
    return TO_USER_ROLE_DTO[data];
  }
}
