<mat-spinner
  *ngIf="mode === 'spinner'"
  [class.skeleton]="forSkeleton"
  [diameter]="size"
></mat-spinner>
<mat-progress-bar
  *ngIf="mode === 'bar'"
  color="primary"
  mode="indeterminate"
></mat-progress-bar>
