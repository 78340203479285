<div class="container">
  <table class="table" mat-table [dataSource]="(tableData$ | async) ?? []">
    <ng-content></ng-content>
    <tr
      class="table__header"
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      class="table__row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <mat-paginator
    #paginator
    [pageIndex]="paginationFilters.pageNumber"
    [length]="(itemsCount$ | async) ?? 0"
    [pageSize]="paginationFilters.pageSize"
    [pageSizeOptions]="[5, 10, 50, 100]"
  ></mat-paginator>
</div>
