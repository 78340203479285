import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

/** Password field control. */
@Component({
  selector: 'hmc-password-field-control',
  templateUrl: './password-field-control.component.html',
  styleUrls: ['./password-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFieldControlComponent {

  /** Placeholder. */
  @Input()
  public placeholder = '';

  /** Label text. */
  @Input()
  public labelText = '';

  /** Form control setter. */
  @Input()
  public set control(value: FormControl<string> | null) {
    if (value != null) {
      this.control$.next(value);
    }
  }

  /** Should show password. */
  protected shouldShowPassword = false;

  /** Password field control. */
  protected readonly control$ = new ReplaySubject<FormControl<string>>(1);

  /** Toggle show password state. */
  protected toggleShowPassword(): void {
    this.shouldShowPassword = !this.shouldShowPassword;
  }
}
