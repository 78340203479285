import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const DEFAULT_DURATION_MS = 3000;

/** Notification service. */
@Injectable({ providedIn: 'root' })
export class NotificationService {

  public constructor(
    private readonly snackBar: MatSnackBar,
  ) { }

  /**
   * Notify a user with a message.
   * @param message Human-readable message.
   * @param duration Duration of notification message.
   */
  public notify(message: string, duration: number = DEFAULT_DURATION_MS): void {
    this.snackBar.open(
      message,
      '',
      {
        duration,
        horizontalPosition: 'left',
        panelClass: 'hmc-snackbar-notification',
      },
    );
  }
}
