<div class="avatar-container">
  <img
    class="avatar"
    [class.small]="size === 'small'"
    [class.large]="size === 'large'"
    *ngIf="shouldShowUserAvatar; else namedAvatar"
    [src]="avatarUrl"
    alt=""
  />

  <ng-template #namedAvatar>
    <div
      *ngIf="namedAvatarText"
      class="named-avatar"
      [class.small]="size === 'small'"
      [class.large]="size === 'large'"
    >
      <span class="named-avatar__symbols">{{ namedAvatarText }}</span>
    </div>
  </ng-template>
</div>
